import {
    createRouter,
    createWebHistory
} from "vue-router";
import main from "../views/main.vue";

const routes = [{
    path: '/',
    redirect: '/ProjectManage'
}, {
    path: "/",
    name: "Home",
    component: main,
    children: [{
        path: "/dashboard",
        name: "Dashboard",
        meta: {
            title: '首页'
        },
        component: () => import(
            /* webpackChunkName: "dashboard" */
            // "../views/Dashboard.vue")
            "../views/BusinessHome.vue")
    }, {
        path: "/businesshome",
        name: "BusinessHome",
        meta: {
            title: '业务首页'
        },
        component: () => import(
            /* webpackChunkName: "dashboard" */
            "../views/BusinessHome.vue")
    }

        , {
            path: "/userinfo",
            name: "userinfo",
            meta: {
                title: '用戶中心'
            },
            component: () => import("../views/base/userinfo.vue")
        }, {
            path: "/usermessage",
            name: "usermessage",
            meta: {
                title: '消息中心'
            },
            component: () => import("../views/base/usermessage.vue")
        }, {
            path: '/404',
            name: '404',
            meta: {
                title: '找不到页面'
            },
            component: () => import('../views/404.vue')
        }, {
            path: '/403',
            name: '403',
            meta: {
                title: '没有权限'
            },
            component: () => import('../views/403.vue')
        }
        //----------------------------------------------------------sys-------------------------------------------------
        , {
            path: '/ProjectManage',
            name: 'ProjectManage',
            meta: {
                title: '项目管理'
            },
            component: () => import('../views/sys/project')
        },
        {
            path: '/test',
            name: 'test',
            meta: {
                title: '测试'
            },
            component: () => import('../views/sys/project/report')
        },

        {
            path: '/tt',
            name: 'tt',
            meta: {
                title: 'tsts'
            },
            component: () => import('../views/sys/project/apconf')
        },
        {
            path: '/groupmanage',
            name: 'groupmanage',
            meta: {
                title: '组织管理'
            },
            component: () => import('../views/sys/groupmanage.vue')
        },
        {
            path: '/aclmanage',
            name: 'aclmanage',
            meta: {
                title: '权限管理'
            },
            component: () => import('../views/sys/aclmanage.vue')
        }, {
            path: '/menu',
            name: 'menu',
            meta: {
                title: '菜单管理'
            },
            component: () => import('../views/sys/menu.vue')
        }
        //---------------------------------xxm add  start------------------------------------//
        //---------------------------------客户 开始------------------------------------//
        ,
        {
            path: '/customerList',
            name: 'customerList',
            meta: {
                title: '客户管理'
            },
            component: () => import('../views/customer/list.vue')
        },
        {
            path: '/followCustomerList',
            name: 'followCustomerList',
            meta: {
                title: '待跟进客户'
            },
            component: () => import('../views/customer/followList.vue')
        },
        {
            path: '/customerViewPage/:customerid',
            name: 'customerViewPage',
            meta: {
                title: '客户信息'
            },
            component: () => import('../views/customer/viewPage.vue')
        },
        {
            path: '/customerInfoUpdateList',
            name: 'customerInfoUpdateList',
            meta: {
                title: '企业资料更新'
            },
            component: () => import('../views/customer/infoUpdateList.vue')
        },
        {
            path: '/customerWarehouseList',
            name: 'customerWarehouseList',
            meta: {
                title: '客户仓库'
            },
            component: () => import('../views/customer/warehouseList.vue')
        },
        {
            path: '/customerEdit/:customerid',
            name: 'customerEdit',
            meta: {
                title: '客户编辑'
            },
            component: () => import('../views/customer/edit.vue')
        },

        //---------------------------------客户 结束------------------------------------//
        //---------------------------------项目 开始------------------------------------//
        {
            path: '/projectList',
            name: 'projectList',
            meta: {
                title: '项目管理'
            },
            component: () => import('../views/project/list.vue')
        },
        {
            path: '/projectBuilding',
            name: 'projectBuilding',
            meta: {
                title: '待完善项目'
            },
            component: () => import('../views/project/buildingList.vue')
        },
        {
            path: '/uncompleteList',
            name: 'uncompleteList',
            meta: {
                title: '待完善项目'
            },
            component: () => import('../views/project/uncompleteList.vue')
        },
        {
            path: '/proExtInfo',
            name: 'proExtInfo',
            meta: {
                title: '项目扩展信息'
            },
            component: () => import('../views/project/extInfo.vue')
        },
        {
            path: '/projectVerifying',
            name: 'projectVerifying',
            meta: {
                title: '待验证项目'
            },
            component: () => import('../views/project/verifyingList.vue')
        },
        // {
        //     path: '/proEdit/:customerid/:pmid',
        //     name: 'proEdit',
        //     meta: {
        //         title: '编辑项目'
        //     },
        //     component: () => import('../views/project/edit.vue')
        // },
        {
            path: '/projectEdit',
            name: 'projectEdit',
            meta: {
                title: '编辑项目'
            },
            component: () => import('../views/project/projectEdit.vue')
        },
        //---------------------------------项目 结束------------------------------------//

        //---------------------------------验证计划 开始------------------------------------//
        {
            path: '/verifyPlanEdit/:customerid/:planid',
            name: 'verifyPlanEdit',
            meta: {
                title: '验证计划编辑'
            },
            component: () => import('../views/verify/edit.vue')
        },
        //---------------------------------验证计划 结束------------------------------------//

        //---------------------------------订单 xxm add 2021-05-04 start------------------------------------//
        {
            path: '/orderList',
            name: 'orderList',
            meta: {
                title: "订单列表"
            },
            component: () => import('../views/order/list.vue')
        },
        {
            path: '/orderView',
            name: 'orderView',
            meta: {
                title: "查看订单"
            },
            component: () => import('../views/order/orderView.vue')
        },
        {
            path: '/orderProcessing',
            name: 'orderProcessing',
            meta: {
                title: '待处理订单'
            },
            component: () => import('../views/order/processingList.vue')
        },
        {
            path: '/orderFinalizeList',
            name: 'orderFinalizeList',
            meta: {
                title: '定稿列表'
            },
            component: () => import('../views/order/finalizeList.vue')
        }, {
            path: '/createOrder',
            name: 'createOrder',
            meta: {
                title: "新建订单"
            },
            component: () => import('../views/order/createOrder.vue')
        }
        // ,
        // {
        //     path: '/createOrder/:customerid',
        //     name: 'createOrder',
        //     meta: {
        //         title: "新建订单"
        //     },
        //     component: () => import('../views/order/createOrder.vue')
        // }
        //---------------------------------订单 xxm add 2021-05-04 end------------------------------------//
        //---------------------------------跟进信息 xxm add 2021-05-04 start------------------------------------//
        ,
        {
            path: '/followrecordHistory',
            name: 'followrecordHistory',
            meta: {
                title: '历史跟进记录'
            },
            component: () => import('../views/follow/recordList.vue')
        },
        {
            path: '/followList',
            name: 'followList',
            meta: {
                title: '跟进计划列表'
            },
            component: () => import('../views/follow/followList.vue')
        },
        {
            path: '/followEdit',
            name: 'followEdit',
            meta: {
                title: '编辑跟进'
            },
            component: () => import('../views/follow/followEdit.vue')
        },
        {
            path: '/followPlanRecord',
            name: 'followPlanRecord',
            meta: {
                title: '编辑跟进记录'
            },
            component: () => import('../views/follow/followRecordEdit.vue')
        },
        {
            path: '/followRecordAdd',
            name: 'followRecordAdd',
            meta: {
                title: "添加跟进记录"
            },
            component: () => import('../views/follow/followRecordAdd.vue')
        },
        {
            path: '/followRecordEdit',
            name: 'followRecordEdit',
            meta: {
                title: "编辑跟进记录"
            },
            component: () => import('../views/follow/followRecordAdd.vue')
        },
        //---------------------------------信息 xxm add 2021-05-04 end------------------------------------//
        //---------------------------------方案 start------------------------------------//
        {
            path: '/schemeProcessing',
            name: 'schemeProcessing',
            meta: {
                title: '待处理方案'
            },
            component: () => import('../views/scheme/list.vue')
        },
        {
            path: '/schemeFinalize',
            name: 'schemeFinalize',
            meta: {
                title: '定稿方案'
            },
            component: () => import('../views/scheme/finalizeList.vue')
        }
        //---------------------------------方案 end------------------------------------//
        //---------------------------------报告 start------------------------------------//
        ,
        {
            path: '/reportProcessing',
            name: 'reportProcessing',
            meta: {
                title: '待处理报告'
            },
            component: () => import('../views/report/list.vue')
        },
        {
            path: '/reportFinalize',
            name: 'reportFinalize',
            meta: {
                title: '定稿报告'
            },
            component: () => import('../views/report/finalizeList.vue')
        }
        //---------------------------------报告 end------------------------------------//
        //---------------------------------报告及方案 start------------------------------------//
        ,
        {
            path: '/reportschemeList',
            name: 'reportschemeList',
            meta: {
                title: '报告及方案列表'
            },
            component: () => import('../views/reportscheme/list.vue')
        }
        //---------------------------------报告及方案 end------------------------------------//
        //---------------------------------xxm add end------------------------------------//
        , {
            path: '/onlineusers',
            name: 'reportschemeList',
            meta: {title: '在线用户'},
            component: () => import('../views/tool/onlineusers.vue')
        }
        , {
            path: '/noticemanger',
            name: 'noticemanger',
            meta: {title: '公告管理'},
            component: () => import('../views/tool/noticemanger.vue')
        }
        , {
            path: '/syslogs',
            name: 'syslogs',
            meta: {title: '操作日志'},
            component: () => import('../views/tool/syslog.vue')
        },


    ]
}, {
    path: "/login",
    name: "Login",
    meta: {
        title: '登录'
    },
    component: () => import("../views/Login.vue")
}, {
    path: "/resetpassword",
    name: "resetpassword",
    meta: {
        title: '重置密码'
    },
    component: () => import("../views/Resetpassword.vue")
}];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});


export default router;