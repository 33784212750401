/**
 * Created by bootdo.
 * 用户相关api
 */
import * as API from '../../utils/request';

export default {
    //登录
    login: params => {
        return API.POSTARRAY('user/na_login', params)
    },
    wxlogin: params => {
        return API.POSTARRAY('user/na_wxlogin', params)
    },
    na_wxauthcode: params => {
        return API.GET('wx/na_wxauthcode', params)
    },
    getdxcode: params => {
        return API.GET('user/na_getLoginCode', params)
    },
    //登出
    logout: params => {
        return API.GET('user/logout', params)
    },

    sso: params => {
        return API.GET('user/na_sso', params)
    },

    currentUser: params => {
        return API.GET('user/currentUser', params)
    },
    //修改个人头像
    upUserAvatar: params => {
        return API.UPLOAD('user/upUserAvatar', params)
    },
    //修改用户信息
    updateUser: params => {
        return API.POSTARRAY('user/update', params)
    },
    //获得通知消息

    getMsgCount: params => {
        return API.GET('msg/getMsgCount', params)
    },
    getPageMsg: params => {
        return API.GET('msg/getMsg', params)
    },
    changestatus: params => {
        return API.POSTARRAY('msg/changestatus', params)
    },
    delMsg: params => {
        return API.POSTARRAY('msg/delMsg', params)
    },
    //
    add: params => {
        return API.POST('user/add', params)
    },
    extUserName: params => {
        return API.GET('user/na_existenceUserName', params)
    },
    delete: params => {
        return API.DELETE('user/delete', params)
    },
    getUserList: params => {
        return API.GET('user/getUserList', params)
    },
    getUserListByDept: params => {
        return API.GET('user/getUserListByDept', params)
    },

    //注册用户
    register: params => {
        return API.POST('user/na_register', params)
    },
    //获取注册验证码
    getcode: params => {
        return API.POSTARRAY('user/na_getResetPasswordCode', params)
    },
    //重置密码
    resetpassword: params => {
        return API.POSTARRAY('user/na_resetPassword', params)
    },
    // 微信认证
    getUserOauthUrl: params => {
        return API.GET('wx/getOauthUrl', params)
    },
    // 微信认证状态刷新
    getWXAuthStatus: params => {
        return API.GET('wx/getStatus', params)
    },
    getWXAuthUser: params => {
        return API.GET('user/na_auth_status', params)
    },
    // 绑定电话号码
    bindingTelephone: params => {
        return API.GET('user/bindingTelephone', params)
    },
    // 获取修改手机号码
    changePhone: params => {
        return API.GET('user/changePhone', params)
    },
    //查询同部门及子部门用户
    searchUser: params => {
        return API.POSTARRAY('user/searchUser', params)
    },
    listNoPage: params => {
        return API.GET('user/listNoPage', params)
    },
}
