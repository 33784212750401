import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios';
import {getUrl} from './utils/request'
import store from './store'
import './assets/css/icon.css'
import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
// import zhCn from 'element-plus/lib/locale/lang/zh-cn'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
const app = createApp(App)
app.use(ElementPlus,{ locale:zhCn })

if (!localStorage.getItem('access-token') && window.location.hash.indexOf("login") === -1) {
    router.push('/login');
}
app.config.globalProperties.$getAuth = (url) => {
    return store.state.user_auth[url || window.location.pathname] || {}
}

axios.defaults.withCredentials = true;
axios.defaults.headers.common['Authorization'] = window.localStorage.getItem('access-token');
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';//配置请求头
axios.interceptors.request.use(
    config => {
        config.headers.Authorization = window.localStorage.getItem('access-token');
        return config
    },
    error => {
        return Promise.reject(error)
    }
);
//状态验证
axios.interceptors.response.use((response) => {
        return response;
    }, (err) => {
        store.state.loading = false;
        if (err && err.response) {
            switch (err.response.status) {
                case 401:
                    localStorage.removeItem('access-token')
                    router.replace({path: "/login"}).then(r => {
                    });
                    break;
                case 402:
                case 403:
                    localStorage.removeItem('access-token')
                    $message.error(402 === err.response.status ? '您的账号出现异常登录！请尽快修改密码' : '越权操作！');
                    router.replace({path: "/login"});
                    break;
                case 500:
                    this.$message.error(err.response.msg||'服务异常....');
                    break;
                default:
                case 503:
                    this.$message.error('服务繁忙中....');
                    break;
            }
        }
        return Promise.reject(err);
    }
);
//跳转验证
router.beforeEach((to, from, next) => {
    document.title = `中孚验证 | ${to.meta.title} `;
    if (!store.state.cuttuser.id && ",/login,/findpassword,".indexOf(to.path) === -1) {
        let token = localStorage.getItem('access-token')
        if (!token || token.length < 10) {
            next({path: '/login', query: {redirect: to.fullPath}});
            return
        }
        axios.get(getUrl() + '/yanzheng-web/user/currentUser?auth=1').then(res => {
            if (res.status === 200) {
                store.commit("update_user_auth", res.data.auth);
                store.commit("update_cuttuser", res.data.user);
                next()
            } else {
                next({path: '/login', query: {redirect: to.fullPath}});
            }
        }).catch(error => {
            next({path: '/login', query: {redirect: to.fullPath}});
        })
        return
    }
    next()
})

app.use(store).use(router).mount('#app')



