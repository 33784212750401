import axios from 'axios';
import qs from 'qs';
// let base = 'http://127.0.0.1:8830';
// let base = 'http://47.110.94.129:8200';
let base = 'https://api.zfyanzheng.com';

const getUrl = function(){
    return base;
}

//通用方法
export const GET = (url, params,mode='web') => {
    return axios.get(`${base}/yanzheng-${mode}/${url}`, { params: params },{  withCredentials: true   }).then(res => res.data)
}

export const POST = (url, params,mode='web') => {
    return axios.post(`${base}/yanzheng-${mode}/${url}`,  params,{  withCredentials: true   }).then(res => res.data)
}


export const PATCH = (url, params,mode='web') => {
    return axios.patch(`${base}/yanzheng-${mode}/${url}`, params,{  withCredentials: true   }).then(res => res.data)
}
export const PUT = (url, params,mode='web') => {
    return axios.put(`${base}/yanzheng-${mode}/${url}`, params,{  withCredentials: true   }).then(res => res.data)
}

export const DELETE = (url, params,mode='web') => {
    return axios.delete(`${base}/yanzheng-${mode}/${url}`, { params: params },{  withCredentials: true   }).then(res => res.data)
}

export const POSTARRAY = (url, params,mode='web') => {//提交数组
    return  axios.post(`${base}/yanzheng-${mode}/${url}`, qs.stringify( params, { indices: false,withCredentials: true   })).then(res => res.data)
}
export const UPLOAD = (url, params,mode='web') => {//上传文件
    return  axios.post(`${base}/yanzheng-${mode}/${url}`,  params , {  headers: {'Content-Type': 'multipart/form-data'},withCredentials: true  }).then(res => res.data)
}

export const DOWNLOADFILE= (url,params,mode='web')=> {//下载文件
    return axios.post(`${base}/yanzheng-${mode}/${url}`,qs.stringify( params, { indices: false }),{responseType: 'arraybuffer',withCredentials: true  },).then(res=>res)
}


const service = axios.create({
    // process.env.NODE_ENV === 'development' 来判断是否开发环境
    // easy-mock服务挂了，暂时不使用了
    baseURL: 'http://127.0.0.1:8200',
    timeout: 5000
});

service.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        console.log(error);
        return Promise.reject();
    }
);

service.interceptors.response.use(
    response => {
        if (response.status === 200) {
            return response.data;
        } else {
            Promise.reject();
        }
    },
    error => {
        console.log(error);
        return Promise.reject();
    }
);
export { getUrl};
export default service;
